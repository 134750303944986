import { Selection, Key } from "@react-types/shared"
import { createContext, useContext } from 'react'


export type FormDataContextType = {
	// services
	services: string[] | undefined
	setServices: (c: string[] | undefined) => void
	// job details
	jobDetails: string | undefined
	setJobDetails: (c: string | undefined) => void
	// frequency
	frequency: Selection
	setFrequency: (keys: Selection) => void
	// frequency other option
	frequencyOther: string | undefined
	setFrequencyOther: (c: string | undefined) => void
	// how soom
	howSoon: string | undefined
	setHowSoon: (c: string | undefined) => void
	// property type
	propertyType: Key | undefined
	setPropertyType: (keys: Key) => void
	// first name
	firstName: string | undefined
	setFirstName: (c: string | undefined) => void
	// last name
	lastName: string | undefined
	setLastName: (c: string | undefined) => void
	// email
	email: string | undefined
	setEmail: (c: string | undefined) => void
	// phone number
	phone: string | undefined
	setPhone: (c: string | undefined) => void
	// service address
	address: string | undefined
	setAddress: (c: string | undefined) => void
	// how did you hear about us
	referralSource: Selection
	setReferralSource: (keys: Selection) => void
	// referral source other
	referralSourceOther: string | undefined
	setReferralSourceOther: (c: string | undefined) => void
	// may we send you offers
	subscribe: boolean
	setSubscribe: (keys: boolean) => void
	// anything else to add
	otherDetails: string | undefined
	setOtherDetails: (c: string | undefined) => void
}

export const FormDataContext = createContext<FormDataContextType>({
	// services
	services: undefined,
	setServices: () => { },
	// job details
	jobDetails: undefined,
	setJobDetails: () => { },
	// frequency
	frequency: new Set(),
	setFrequency: () => { },
	// frequency other option
	frequencyOther: undefined,
	setFrequencyOther: () => { },
	// how soom
	howSoon: undefined,
	setHowSoon: () => { },
	// property type
	propertyType: undefined,
	setPropertyType: () => { },
	// first name
	firstName: undefined,
	setFirstName: () => { },
	// last name
	lastName: undefined,
	setLastName: () => { },
	// email
	email: undefined,
	setEmail: () => { },
	// phone number
	phone: undefined,
	setPhone: () => { },
	// service address
	address: undefined,
	setAddress: () => { },
	// how did you hear about us
	referralSource: new Set(),
	setReferralSource: () => { },
	// referral source other
	referralSourceOther: undefined,
	setReferralSourceOther: () => { },
	// may we send you offers
	subscribe: true,
	setSubscribe: () => { },
	// anything else to add
	otherDetails: undefined,
	setOtherDetails: () => { },
})

export const useFormDataContext = () => useContext(FormDataContext)
