import { PowerwashIcon, GutterIcon, WindowWashIcon, HouseIcon, RoofIcon, SvgIcon } from "./components/icons"
import { IconProps } from "./components/icons/IconsProps"

export type ServiceType = {
	label: string,
	value: string,
	description: string,
	link?: string,
	icon?: SvgIcon
}

let serviceIconProps: IconProps = { size: '45' }

export const serviceOptions: ServiceType[] = [
	{ label: "Power Washing", value: "powerWashing", description: "If it needs power washing, we'll power wash it.", link: "Feel the power", icon: PowerwashIcon(serviceIconProps) },
	{ label: "Window Washing", value: "windowWashing", description: "Looking out a dirty old window.", link: "OMG, I can see", icon: WindowWashIcon(serviceIconProps) },
	{ label: "Gutter Cleaning", value: "gutterCleaning", description: "The water must go somewhere.", link: "Clear those drains", icon: GutterIcon(serviceIconProps) },
	{ label: "House Washing", value: "houseWashing", description: "We'll make the exterior of you home or business shine.", link: "Let's razzle and dazzle", icon: HouseIcon(serviceIconProps) },
	{ label: "Roof Washing", value: "roofWashing", description: "Washy, washy.", link: "Oooh yeahh", icon: RoofIcon(serviceIconProps) },
	{ label: "Trash Can Cleaning", value: "trash", description: "Squeaky clean cans.", link: "let's take out the trash", icon: RoofIcon(serviceIconProps) },
	{ label: "Other", value: "other", description: "" },
]

export type FrequencyType = {
	label: string,
	value: string,
}

export const frequencyOptions: FrequencyType[] = [
	{ label: "Once", value: "once" },
	{ label: "Once a week", value: "weekly" },
	{ label: "Once a month", value: "monthly" },
	{ label: "Once a year", value: "annually" },
	{ label: "Other", value: "other" },
]

export type PropertyType = {
	label: string,
	value: string,
}

export const propertyTypeOptions: PropertyType[] = [
	{ label: "Residential", value: "residential" },
	{ label: "Commercial", value: "commercial" },
]

export type ReferralSource = {
	label: string,
	value: string,
}

export const referralSourceOptions: ReferralSource[] = [
	{ label: "Google Search", value: "searchEngine" },
	{ label: "Facebook", value: "facebook" },
	{ label: "Instagram", value: "instagram" },
	{ label: "A friend", value: "friend" },
	{ label: "A flyer", value: "flyer" },
	{ label: "Other", value: "other" },
]

export type SubscribeType = {
	label: string,
	value: string,
}

export const subscribeOptions: SubscribeType[] = [
	{ label: "Yes!", value: "yes" },
	{ label: "No, thank you", value: "no" },
]

export type Review = {
	name: string,
	rating: number,
	comment?: string,
	source?: string,
	time?: string
}

export const displayReviews: Review[] = [
	{ name: "Facey", rating: 5, comment: "Incredible." },
	{ name: "Miss Liz", rating: 5, comment: "Who would have thought?" },
]

