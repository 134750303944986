import React from "react";
import { ContactForm, MiniContactForm, Navigation } from '../components'
import { ConstructionIcon, HessezLogo } from "../components/icons";
import { Link } from "@nextui-org/react";

function Construction() {

	let styles: React.CSSProperties = {
		margin: "30px auto",
		maxWidth: "1400px",
	}

	return (
		<>
			<div className="px-6 sm:px-10 flex justify-center md:justify-start" style={styles}>
				<Link href="/"><HessezLogo size="200" /></Link>
			</div>

			<div className={"px-6 md:flex sm:px-10 gap-16"} style={styles}>

				<div className={"w-100 md:w-50"}>
					<div className={"flex justify-center"}>
						<ConstructionIcon size="300" />
					</div>

					<h1 className="text-3xl text-center pb-4 md:text-4xl md:text-left">Website Under Construction</h1>
					<p>Thank you for stopping by! We're currently giving our digital home a fresh coat of paint. While we're busy hammering away, we want you to know that our commitment to exceptional service remains unwavering.</p>
					<br />
					<p>Please fill out the form with details about the services you need, and our service representatives will get back to you as soon as possible.</p>
				</div>

				<div className={"m-auto pt-10 mb-20 flex justify-center"}>
					<MiniContactForm />
				</div>

			</div>
		</>
	)
}

export default Construction;