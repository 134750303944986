import React, { createContext, useState } from 'react';
import { Selection, Key } from "@react-types/shared"
import './App.css';
import { FormDataContext } from './FormDataContext'
import { NextUIProvider } from "@nextui-org/react";
import { useNavigate, Routes, Route } from 'react-router-dom';
import { Home, About, Services, Contact, Construction } from './pages'

function App() {
	const navigate = useNavigate();
	const [services, setServices] = useState<string[]>()
	const [jobDetails, setJobDetails] = useState<string>()
	const [frequency, setFrequency] = useState<Selection>(new Set())
	const [frequencyOther, setFrequencyOther] = useState<string>()
	const [howSoon, setHowSoon] = useState<string>()
	const [propertyType, setPropertyType] = useState<Key>()
	const [firstName, setFirstName] = useState<string>()
	const [lastName, setLastName] = useState<string>()
	const [email, setEmail] = useState<string>()
	const [phone, setPhone] = useState<string>()
	const [address, setAddress] = useState<string>()
	const [referralSource, setReferralSource] = useState<Selection>(new Set())
	const [referralSourceOther, setReferralSourceOther] = useState<string>()
	const [subscribe, setSubscribe] = useState<boolean>(true)
	const [otherDetails, setOtherDetails] = useState<string>()

	return (
		<NextUIProvider navigate={navigate}>
			<main className="hessez text-foreground bg-background">
				<FormDataContext.Provider
					value={{
						// services
						services: services,
						setServices: setServices,
						// job details
						jobDetails: jobDetails,
						setJobDetails: setJobDetails,
						// frequency
						frequency: frequency,
						setFrequency: setFrequency,
						// frequency other option
						frequencyOther: frequencyOther,
						setFrequencyOther: setFrequencyOther,
						// how soom
						howSoon: howSoon,
						setHowSoon: setHowSoon,
						// property type
						propertyType: propertyType,
						setPropertyType: setPropertyType,
						// first name
						firstName: firstName,
						setFirstName: setFirstName,
						// last name
						lastName: lastName,
						setLastName: setLastName,
						// email
						email: email,
						setEmail: setEmail,
						// phone number
						phone: phone,
						setPhone: setPhone,
						// service address
						address: address,
						setAddress: setAddress,
						// how did you hear about us
						referralSource: referralSource,
						setReferralSource: setReferralSource,
						// referral source other
						referralSourceOther: referralSourceOther,
						setReferralSourceOther: setReferralSourceOther,
						// may we send you offers
						subscribe: subscribe,
						setSubscribe: setSubscribe,
						// anything else to add
						otherDetails: otherDetails,
						setOtherDetails: setOtherDetails,
					}}
				>
					<Routes>
						<Route path="/" element={<Construction />} />
						<Route path="/contact" element={<Contact />} />
						{/*
						<Route path="/" element={<Home />} />
						<Route path="/about" element={<About />} />
						<Route path="/services" element={<Services />} />
						*/}
					</Routes>
				</FormDataContext.Provider>

			</main>
		</NextUIProvider>
	);
}

export default App;
