import React from "react";
import { Navigation, ContactForm } from '../components'
import { HessezLogo } from "../components/icons";
import { Link } from "@nextui-org/react";

function Contact() {
	let styles: React.CSSProperties = {
		margin: "30px auto",
		maxWidth: "1400px",
	}

	return (
		<div>
			{/*<Navigation />
			<div className={"bg-gradient-to-tr from-primary to-primary-light"} style={{ height: "10vh" }}>
			</div>*/}
			<div className="px-10 flex justify-center md:justify-start" style={styles}>
				<Link href="/">
					<HessezLogo size="200" />
				</Link>
			</div>

			<ContactForm />
		</div>
	)
}

export default Contact;


