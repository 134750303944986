import React from "react";
import { IconProps } from "./IconsProps";
import { SvgIcon } from "./SvgIcon";

function RoofIcon(props: IconProps): SvgIcon {
	const { color, size, height, width } = props

	return (
		<svg width={size || width || 24} height={size || height || 24} viewBox="0 0 100 100" fill={color || 'currentColor'} xmlns="http://www.w3.org/2000/svg">
			<path d="m 3.0602088,71.406946 c -0.296035,-0.14792 -0.707645,-0.57993 -0.981612,-0.92582 0,0 -0.309518,-0.33566 -0.309518,-0.84423 v -5.92074 -5.92074 c 0,0 0.04711,-0.61092 0.175684,-0.88158 0.182154,-0.38354 0.577196,-0.61392 0.577196,-0.61392 L 25.956206,42.245826 c 16.1278,-9.67224 23.622259,-14.0541 24.03727,-14.0541 0.409348,0 4.523513,2.35116 12.809211,7.32018 6.713393,4.02611 12.294373,7.32019 12.402176,7.32019 0.113523,0 0.196003,-2.7691 0.196003,-6.58079 0,-7.00384 -0.0179,-7.24686 0.933682,-7.86714 0,0 0.321676,-0.18376 0.767332,-0.19244 2.440472,-0.0475 7.341563,0 7.341563,0 0,0 5.15966,8.1e-4 7.34157,0 0.42108,-2.3e-4 0.72303,0.20049 0.72303,0.20049 0.96881,0.64641 0.97798,0.53903 0.97798,13.40449 v 12.12619 l 1.99152,1.19008 c 1.47007,0.87848 2.01408,1.46909 2.40597,1.90586 0,0 0.33581,0.49801 0.33843,0.77658 0.02,2.13278 3.4e-4,5.87383 3.4e-4,5.87383 4e-4,7.1236 -0.45748,7.65159 -1.89312,8.14984 0,0 -0.68676,0.0524 -0.91372,-0.0792 -1.4556,-0.844 -22.709962,-13.62404 -22.709962,-13.62404 l -22.709962,-13.624 -22.661894,13.59519 C 4.5317588,71.766196 4.1730988,71.963016 3.0602088,71.406936 Z m 25.0824022,-19.00835 21.099353,-12.6517 0.753555,-0.0111 0.753547,-0.0111 21.314658,12.78138 c 11.723059,7.02975 21.387309,12.82109 21.476119,12.86963 0.0888,0.0486 0.16148,-1.31727 0.16148,-3.03515 v -3.12341 l -21.726695,-13.0367 c -11.949684,-7.17021 -21.840287,-13.03675 -21.979109,-13.03675 -0.138831,0 -10.029434,5.86654 -21.979118,13.03675 L 6.2897028,59.217206 v 3.11829 3.11828 l 0.376774,-0.2017 c 0.207225,-0.11095 9.8714892,-5.89498 21.4761342,-12.85341 z m 61.037422,-6.9829 v -5.81309 h -4.7425 -4.742509 l 0.05972,3.05572 0.05972,3.05573 4.521289,2.73991 c 2.48671,1.50696 4.59395,2.74777 4.68276,2.75736 0.0888,0.01 0.16148,-2.59843 0.16148,-5.79563 z m 0,-11.51853 v -1.18415 h -4.73659 -4.736596 v 1.18415 1.18415 h 4.736596 4.73659 z" />
		</svg>
	)
}

export default RoofIcon;