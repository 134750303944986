import React from "react";
import { Chip, useCheckbox, VisuallyHidden, tv } from "@nextui-org/react";

const checkbox = tv({
	slots: {
		base: "px-4 py-4 max-w-full w-full",
		content: "text-small [@media(min-width:420px)]:text-center"
	},
	variants: {
		isSelected: {
			true: {
				base: "bg-secondary-400",
				content: "text-secondary-foreground"
			},
			false: {
				base: "bg-secondary-50 data-[focus=true]:bg-secondary-light",
				content: "text-secondary-dark"
			}
		},
		isFocusVisible: {
			true: {
				base: ""
			}
		}
	}
})

function MultiSelect(props: any) {
	const {
		children,
		isSelected,
		isFocusVisible,
		getBaseProps,
		getInputProps
	} = useCheckbox({ ...props })

	const styles = checkbox({ isSelected, isFocusVisible })

	return (
		<label {...getBaseProps()} className="w-full max-w-full">
			<VisuallyHidden>
				<input {...getInputProps()} />
			</VisuallyHidden>
			<Chip
				classNames={{
					base: styles.base(),
					content: styles.content()
				}}
				color="secondary"
				variant="flat"
			>
				{children ? children : isSelected ? "Enabled" : "Disabled"}
			</Chip>
		</label>
	)
}

export default MultiSelect;