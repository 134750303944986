import React from "react";
import { Selection } from "@react-types/shared"
import { Select, SelectItem } from "@nextui-org/react";

interface DropDownProps {
	selectedKeys: Selection,
	onSelectionChange: (keys: Selection) => void,
	items: { value: string, label: string }[],
	defaultItem: any,
	className: string
}

function DropDown(props: DropDownProps) {
	const { selectedKeys, onSelectionChange, items, defaultItem, className } = props

	return (
		<Select
			selectedKeys={selectedKeys}
			onSelectionChange={onSelectionChange}
			className={className}
			color="secondary"
			classNames={{
				value: "group-data-[has-value=true]:text-secondary",
				trigger: "bg-secondary-50 data-[hover=true]:bg-secondary-100 text-secondary h-10",
				label: "text-secondary",
			}}
			listboxProps={{
				itemClasses: {
					base: [
						"bg-secondary-50",
						"text-secondary",
						"data-[hover=true]:text-secondary-foreground",
						"data-[hover=true]:bg-secondary-400",
						"data-[focus=true]:text-secondary-foreground",
						"data-[focus=true]:bg-secondary-400",
						"data-[selectable=true]:focus:bg-secondary-400",
						"data-[selectable=true]:focus:text-secondary-foreground",
					]
				}
			}}
			defaultSelectedKeys={[defaultItem]}
		>
			{items.map((item: { value: string, label: string }) => (
				<SelectItem key={item.value} value={item.value}>
					{item.label}
				</SelectItem>
			))}
		</Select>
	)
}

export default DropDown;