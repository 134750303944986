import React from "react";
import { IconProps } from "./IconsProps";
import { SvgIcon } from "./SvgIcon";

function ConstructionIcon(props: IconProps): SvgIcon {
	const { size } = props

	return (
		<svg
			viewBox="0 0 500 350"
			width={size}
			height={size}
			xmlns="http://www.w3.org/2000/svg"
			>
			<g>
				<path
					style={{opacity: 0.7, fill: '#777069', fillOpacity: 0.603365}}
					d="m 441.929,388.098 c 0,4.403 -80.805,7.973 -180.482,7.973 -99.677,0 -180.482,-3.569 -180.482,-7.973 0,-4.402 80.805,-7.971 180.482,-7.971 99.677,0 180.482,3.568 180.482,7.971 z" />
				<g>
					<g>
						<g>
							<rect
								x="143"
								y="310"
								style={{fill: "#2a2a2a"}}
								width="196"
								height="22" />
							<g>
								<polygon
									style={{fill: "#2a2a2a"}}
									points="162.75,332 191.478,310 178.257,310 149.529,332 " />
								<polygon
									style={{fill:"#2a2a2a"}}
									points="197.103,332 225.831,310 212.61,310 183.881,332 " />
								<polygon
									style={{fill: "#2a2a2a"}}
									points="265.807,332 294.536,310 281.313,310 252.586,332 " />
								<polygon
									style={{ fill: "#2a2a2a"}}
									points="300.16,332 328.888,310 315.667,310 286.938,332 " />
								<polygon
									style={{ fill: "#2a2a2a" }}
									points="339,332 339,318.544 321.29,332 " />
								<polygon
									style={{ fill: "#2a2a2a" }}
									points="231.455,332 260.183,310 246.962,310 218.233,332 " />
								<polygon
									style={{ fill: "#2a2a2a" }}
									points="157.125,310 143,310 143,319.914 " />
								<polygon
									style={{ fill: "#fbd35b", fillOpacity: 1 }}
									points="143,332 149.529,332 178.257,310 157.125,310 143,319.914 " />
								<polygon
									style={{ fill: "#fbd35b", fillOpacity: 1 }}
									points="183.881,332 212.61,310 191.478,310 162.75,332 " />
								<polygon
									style={{ fill: "#fbd35b", fillOpacity: 1 }}
									points="218.233,332 246.962,310 225.831,310 197.103,332 " />
								<polygon
									style={{ fill: "#fbd35b", fillOpacity: 1 }}
									points="252.586,332 281.313,310 260.183,310 231.455,332 " />
								<polygon
									style={{ fill: "#fbd35b", fillOpacity: 1 }}
									points="286.938,332 315.667,310 294.536,310 265.807,332 " />
								<polygon
									style={{ fill: "#fbd35b", fillOpacity: 1 }}
									points="300.16,332 321.29,332 339,318.544 339,310 328.888,310 " />
							</g>
						</g>
						<g>
							<rect
								x="143.634"
								y="309.397"
								style={{ fill: "#2a2a2a" }}
								width="194.664"
								height="3.2019999" />
							<g>
								<polygon
									style={{ fill: "#2a2a2a" }}
									points="178.121,309.397 174.017,312.599 187.238,312.599 191.342,309.397 " />
								<polygon
									style={{ fill: "#2a2a2a" }}
									points="212.473,309.397 208.369,312.599 221.59,312.599 225.694,309.397 " />
								<polygon
									style={{ fill: "#2a2a2a" }}
									points="281.178,309.397 277.073,312.599 290.295,312.599 294.399,309.397 " />
								<polygon
									style={{ fill: "#2a2a2a" }}
									points="315.529,309.397 311.426,312.599 324.646,312.599 328.75,309.397 " />
								<polygon
									style={{ fill: "#2a2a2a" }}
									points="246.825,309.397 242.722,312.599 255.943,312.599 260.047,309.397 " />
								<polygon
									style={{ fill: "#2a2a2a" }}
									points="143.634,309.397 143.634,312.599 152.923,312.599 156.99,309.397 " />
								<polygon
									style={{ fill: "#fcd51c" }}
									points="156.99,309.397 152.923,312.599 174.017,312.599 178.121,309.397 " />
								<polygon
									style={{ fill: "#fcd51c" }}
									points="191.342,309.397 187.238,312.599 208.369,312.599 212.473,309.397 " />
								<polygon
									style={{ fill: "#fcd51c" }}
									points="225.694,309.397 221.59,312.599 242.722,312.599 246.825,309.397 "
									id="polygon23" />
								<polygon
									style={{ fill: "#fcd51c" }}
									points="260.047,309.397 255.943,312.599 277.073,312.599 281.178,309.397 "
									id="polygon24" />
								<polygon
									style={{ fill: "#fcd51c" }}
									points="294.399,309.397 290.295,312.599 311.426,312.599 315.529,309.397 "
									id="polygon25" />
								<polygon
									style={{ fill: "#fcd51c" }}
									points="328.75,309.397 324.646,312.599 338.298,312.599 338.298,309.397 "
									id="polygon26" />
							</g>
						</g>
						<g
							id="g39">
							<rect
								x="143.634"
								y="309.397"
								style={{ fill: "#8e7e7e" }}
								width="194.664"
								height="3.2019999"
								id="rect27" />
							<g
								id="g38">
								<polygon
									style={{ fill: "#8e7e7e" }}
									points="178.121,309.397 174.017,312.599 187.238,312.599 191.342,309.397 "
									id="polygon27" />
								<polygon
									style={{ fill: "#8e7e7e" }}
									points="212.473,309.397 208.369,312.599 221.59,312.599 225.694,309.397 "
									id="polygon28" />
								<polygon
									style={{ fill: "#8e7e7e" }}
									points="281.178,309.397 277.073,312.599 290.295,312.599 294.399,309.397 "
									id="polygon29" />
								<polygon
									style={{ fill: "#8e7e7e" }}
									points="315.529,309.397 311.426,312.599 324.646,312.599 328.75,309.397 "
									id="polygon30" />
								<polygon
									style={{ fill: "#8e7e7e" }}
									points="246.825,309.397 242.722,312.599 255.943,312.599 260.047,309.397 "
									id="polygon31" />
								<polygon
									style={{ fill: "#8e7e7e" }}
									points="143.634,309.397 143.634,312.599 152.923,312.599 156.99,309.397 "
									id="polygon32" />
								<polygon
									style={{ fill: "#ffe67b" }}
									points="156.99,309.397 152.923,312.599 174.017,312.599 178.121,309.397 "
									id="polygon33" />
								<polygon
									style={{ fill: "#ffe67b" }}
									points="191.342,309.397 187.238,312.599 208.369,312.599 212.473,309.397 "
									id="polygon34" />
								<polygon
									style={{ fill: "#ffe67b" }}
									points="225.694,309.397 221.59,312.599 242.722,312.599 246.825,309.397 "
									id="polygon35" />
								<polygon
									style={{ fill: "#ffe67b" }}
									points="260.047,309.397 255.943,312.599 277.073,312.599 281.178,309.397 "
									id="polygon36" />
								<polygon
									style={{ fill: "#ffe67b" }}
									points="294.399,309.397 290.295,312.599 311.426,312.599 315.529,309.397 "
									id="polygon37" />
								<polygon
									style={{ fill: "#ffe67b" }}
									points="328.75,309.397 324.646,312.599 338.298,312.599 338.298,309.397 "
									id="polygon38" />
							</g>
						</g>
					</g>
					<g
						id="g86">
						<g
							id="g78">
							<g
								id="g53">
								<rect
									x="95"
									y="220"
									style={{ fill: "#2a2a2a" }}
									width="293"
									height="34"
									id="rect40" />
								<g
									id="g52">
									<polygon
										style={{ fill: "#2a2a2a" }}
										points="124.059,254 167.135,220 147.31,220 104.232,254 "
										id="polygon40" />
									<polygon
										style={{ fill: "#2a2a2a" }}
										points="175.569,254 218.645,220 198.821,220 155.743,254 "
										id="polygon41" />
									<polygon
										style={{ fill: "#2a2a2a" }}
										points="278.59,254 321.667,220 301.841,220 258.764,254 "
										id="polygon42" />
									<polygon
										style={{ fill: "#2a2a2a" }}
										points="330.1,254 373.177,220 353.352,220 310.275,254 "
										id="polygon43" />
									<polygon
										style={{ fill: "#2a2a2a" }}
										points="388,254 388,233.813 361.785,254 "
										id="polygon44" />
									<polygon
										style={{ fill: "#2a2a2a" }}
										points="227.079,254 270.156,220 250.33,220 207.253,254 "
										id="polygon45" />
									<polygon
										style={{ fill: "#2a2a2a" }}
										points="115.624,220 95,220 95,235.913 "
										id="polygon46" />
									<polygon
										style={{ fill: "#fcd51c" }}
										points="95,254 104.232,254 147.31,220 115.624,220 95,235.913 "
										id="polygon47" />
									<polygon
										style={{ fill: "#fbd35b", fillOpacity: 1 }}
										points="155.743,254 198.821,220 167.135,220 124.059,254 "
										id="polygon48" />
									<polygon
										style={{ fill: "#fbd35b", fillOpacity: 1 }}
										points="207.253,254 250.33,220 218.645,220 175.569,254 "
										id="polygon49" />
									<polygon
										style={{ fill: "#fbd35b", fillOpacity: 1 }}
										points="258.764,254 301.841,220 270.156,220 227.079,254 "
										id="polygon50" />
									<polygon
										style={{ fill: "#fbd35b", fillOpacity: 1 }}
										points="310.275,254 353.352,220 321.667,220 278.59,254 "
										id="polygon51" />
									<polygon
										style={{ fill: "#fbd35b", fillOpacity: 1 }}
										points="330.1,254 361.785,254 388,233.813 388,220 373.177,220 "
										id="polygon52" />
								</g>
							</g>
							<g
								id="g65">
								<rect
									x="95.393997"
									y="219.802"
									style={{ fill: "#2a2a2a" }}
									width="291.89401"
									height="4.9060001"
									id="rect53" />
								<g
									id="g64">
									<polygon
										style={{ fill: "#2a2a2a" }}
										points="147.106,219.802 140.952,224.708 160.777,224.708 166.931,219.802 "
										id="polygon53" />
									<polygon
										style={{ fill: "#2a2a2a" }}
										points="198.616,219.802 192.462,224.708 212.288,224.708 218.441,219.802 "
										id="polygon54" />
									<polygon
										style={{ fill: "#2a2a2a" }}
										points="301.636,219.802 295.482,224.708 315.309,224.708 321.462,219.802 "
										id="polygon55" />
									<polygon
										style={{ fill: "#2a2a2a" }}
										points="353.146,219.802 346.993,224.708 366.819,224.708 372.973,219.802 "
										id="polygon56" />
									<polygon
										style={{ fill: "#2a2a2a" }}
										points="250.127,219.802 243.973,224.708 263.798,224.708 269.952,219.802 "
										id="polygon57" />
									<polygon
										style={{ fill: "#2a2a2a" }}
										points="95.394,219.802 95.394,224.708 109.322,224.708 115.42,219.802 "
										id="polygon58" />
									<polygon
										style={{ fill: "#fcd51c" }}
										points="115.42,219.802 109.322,224.708 140.952,224.708 147.106,219.802 "
										id="polygon59" />
									<polygon
										style={{ fill: "#fcd51c" }}
										points="166.931,219.802 160.777,224.708 192.462,224.708 198.616,219.802 "
										id="polygon60" />
									<polygon
										style={{ fill: "#fcd51c" }}
										points="218.441,219.802 212.288,224.708 243.973,224.708 250.127,219.802 "
										id="polygon61" />
									<polygon
										style={{ fill: "#fcd51c" }}
										points="269.952,219.802 263.798,224.708 295.482,224.708 301.636,219.802 "
										id="polygon62" />
									<polygon
										style={{ fill: "#fcd51c" }}
										points="321.462,219.802 315.309,224.708 346.993,224.708 353.146,219.802 "
										id="polygon63" />
									<polygon
										style={{ fill: "#fcd51c" }}
										points="372.973,219.802 366.819,224.708 387.288,224.708 387.288,219.802 "
										id="polygon64" />
								</g>
							</g>
							<g
								id="g77">
								<rect
									x="95.393997"
									y="219.802"
									style={{ fill: "#8e7e7e" }}
									width="291.89401"
									height="4.9060001"
									id="rect65" />
								<g
									id="g76">
									<polygon
										style={{ fill: "#8e7e7e" }}
										points="147.106,219.802 140.952,224.708 160.777,224.708 166.931,219.802 "
										id="polygon65" />
									<polygon
										style={{ fill: "#8e7e7e" }}
										points="198.616,219.802 192.462,224.708 212.288,224.708 218.441,219.802 "
										id="polygon66" />
									<polygon
										style={{ fill: "#8e7e7e" }}
										points="301.636,219.802 295.482,224.708 315.309,224.708 321.462,219.802 "
										id="polygon67" />
									<polygon
										style={{ fill: "#8e7e7e" }}
										points="353.146,219.802 346.993,224.708 366.819,224.708 372.973,219.802 "
										id="polygon68" />
									<polygon
										style={{ fill: "#8e7e7e" }}
										points="250.127,219.802 243.973,224.708 263.798,224.708 269.952,219.802 "
										id="polygon69" />
									<polygon
										style={{ fill: "#8e7e7e" }}
										points="95.394,219.802 95.394,224.708 109.322,224.708 115.42,219.802 "
										id="polygon70" />
									<polygon
										style={{ fill: "#ffe67b" }}
										points="115.42,219.802 109.322,224.708 140.952,224.708 147.106,219.802 "
										id="polygon71" />
									<polygon
										style={{ fill: "#ffe67b" }}
										points="166.931,219.802 160.777,224.708 192.462,224.708 198.616,219.802 "
										id="polygon72" />
									<polygon
										style={{ fill: "#ffe67b" }}
										points="218.441,219.802 212.288,224.708 243.973,224.708 250.127,219.802 "
										id="polygon73" />
									<polygon
										style={{ fill: "#ffe67b" }}
										points="269.952,219.802 263.798,224.708 295.482,224.708 301.636,219.802 "
										id="polygon74" />
									<polygon
										style={{ fill: "#ffe67b" }}
										points="321.462,219.802 315.309,224.708 346.993,224.708 353.146,219.802 "
										id="polygon75" />
									<polygon
										style={{ fill: "#ffe67b" }}
										points="372.973,219.802 366.819,224.708 387.288,224.708 387.288,219.802 "
										id="polygon76" />
								</g>
							</g>
						</g>
						<g
							id="g85">
							<g
								id="g81">
								<g
									id="g79">
									<rect
										x="125"
										y="254"
										style={{ fill: "#bfbbb6" }}
										width="22"
										height="131"
										id="rect78" />
									<rect
										x="122"
										y="383"
										style={{ fill: "#d6d3d0" }}
										width="28"
										height="5"
										id="rect79" />
								</g>
								<g
									id="g80">
									<rect
										x="126.056"
										y="254.14301"
										style={{ fill: "#a5a19d" }}
										width="21.257999"
										height="12.265"
										id="rect80" />
								</g>
							</g>
							<g
								id="g84">
								<g
									id="g82">
									<rect
										x="336"
										y="254"
										style={{ fill: "#bfbbb6" }}
										width="21"
										height="131"
										id="rect81" />
									<rect
										x="332"
										y="383"
										style={{ fill: "#d6d3d0" }}
										width="29"
										height="5"
										id="rect82" />
								</g>
								<g
									id="g83">
									<rect
										x="336.185"
										y="254.14301"
										style={{ fill: "#a5a19d" }}
										width="21.259001"
										height="12.265"
										id="rect83" />
								</g>
							</g>
						</g>
					</g>
				</g>
				<g
					id="g89">
					<polygon
						style={{ fill: "#bd7000" }}
						points="309.94,376.889 356.727,376.889 336.782,296.434 329.887,296.434 "
						id="polygon87" />
					<rect
						x="298.84698"
						y="376.616"
						style={{ fill: "#bd7009" }}
						width="68.974998"
						height="11.208"
						id="rect87" />
					<polygon
						style={{ fill: "#bfcbd1" }}
						points="317.487,346.44 313.856,361.096 352.811,361.096 349.179,346.44 "
						id="polygon88" />
					<polygon
						style={{ fill: "#bfcbd1" }}
						points="326.893,308.504 323.9,320.575 342.767,320.575 339.773,308.504 "
						id="polygon89" />
				</g>
				<g
					id="g92">
					<polygon
						style={{ fill: "#f6a908", fillOpacity: 1 }}
						points="350.82,377.547 397.607,377.547 377.661,297.092 370.766,297.092 "
						id="polygon90" />
					<rect
						x="339.72699"
						y="377.27399"
						style={{ fill: "#ffbb61" }}
						width="68.974998"
						height="11.208"
						id="rect90" />
					<polygon
						style={{ fill: "#d9e3e9" }}
						points="358.367,347.098 354.736,361.754 393.691,361.754 390.059,347.098 "
						id="polygon91" />
					<polygon
						style={{ fill: "#d9e3e9" }}
						points="367.773,309.162 364.78,321.233 383.647,321.233 380.653,309.162 "
						id="polygon92" />
				</g>
				<g
					id="g95">
					<polygon
						style={{ fill: "#f6a908", fillOpacity: 1 }}
						points="193.616,376.48 240.403,376.48 220.457,296.025 213.562,296.025 "
						id="polygon93" />
					<rect
						x="182.52299"
						y="376.20901"
						style={{ fill: "#ffbb61" }}
						width="68.973999"
						height="11.207"
						id="rect93" />
					<polygon
						style={{ fill: "#d9e3e9" }}
						points="201.163,346.031 197.532,360.688 236.488,360.688 232.854,346.031 "
						id="polygon94" />
					<polygon
						style={{ fill: "#d9e3e9" }}
						points="210.569,308.096 207.577,320.167 226.443,320.167 223.449,308.096 "
						id="polygon95" />
				</g>
				<g
					id="g96">
					<path
						style={{ fill: "#fbd35b", fillOpacity: 1 }}
						d="m 81.932,244.876 72.97,-60.362 c 0,0 -26.598,-19.492 -56.525,6.633 -29.321,25.594 -16.445,53.729 -16.445,53.729 z"
						id="path95" />
					<path
						style={{ fill: "#f6a908", fillOpacity: 1 }}
						d="m 97.245,266.73 78.967,-65.324 c 0,0 -36.911,-35.042 -72.765,-6.646 -35.853,28.397 -9.755,72.351 -6.202,71.97 z"
						id="path96" />
					<polygon
						style={{ fill: "#fbd35b", fillOpacity: 1 }}
						points="176.144,195.532 180.539,201.664 75.781,288.32 71.385,282.19 94.376,263.172 "
						id="polygon96" />

					<rect
						x="111.081"
						y="185.291"
						transform="matrix(0.7705,-0.6375,0.6375,0.7705,-107.9108,123.1085)"
						style={{ fill: "#fbd35b", fillOpacity: 1 }}
						width="11.822"
						height="52.215"
						id="rect96" />
				</g>
				<g
					id="g101">
					<path
						style={{ fill: "#bcc8ce" }}
						d="m 275.742,376.451 c 0.51,3.403 1.215,8.154 -2.547,9.969 -4.581,2.21 -7.506,-2.787 -8.113,-6.651 l -25.84,-174.2 c -1.499,-9.527 -0.327,-17.627 2.616,-18.095 2.946,-0.455 6.547,6.887 8.045,16.418 -0.001,0 25.589,170.889 25.839,172.559 z"
						id="path97" />
					<path
						style={{ fill: "#909fa3" }}
						d="m 249.549,201.899 c -1.655,-8.538 -4.955,-14.847 -7.691,-14.425 -2.779,0.441 -3.975,7.69 -2.838,16.518 3.502,-0.728 7.013,-1.425 10.529,-2.093 z"
						id="path98" />
					<g
						id="g100">
						<g
							id="g99">
							<polygon
								style={{ fill: "#df6345", fillOpacity: 1 }}
								points="203.552,103.158 256.949,94.765 290.916,136.811 271.488,187.25 218.091,195.645 184.123,153.599 "
								id="polygon98" />
							<path
								style={{ fill: "#dcdcdc" }}
								d="m 273.114,189.262 -55.953,8.794 -35.593,-44.056 20.359,-52.852 55.951,-8.796 35.593,44.057 z m -54.095,3.971 50.844,-7.993 18.499,-48.029 -32.341,-40.033 -50.845,7.992 -18.5,48.028 z"
								id="path99" />
						</g>


					</g>
				</g>
			</g><g
				id="Objects-2"
				transform="translate(-35226.307,-46164.177)">
				<path
					style={{opacity: 0.7, fill: "#777069", fillOpacity: 0.603365 }}
					d="m 441.929,388.098 c 0,4.403 -80.805,7.973 -180.482,7.973 -99.677,0 -180.482,-3.569 -180.482,-7.973 0,-4.402 80.805,-7.971 180.482,-7.971 99.677,0 180.482,3.568 180.482,7.971 z"
					id="path1-4" />
				<g
					id="g87-7">
					<g
						id="g40-9">
						<g
							id="g15-9">
							<rect
								x="143"
								y="310"
								style={{ fill: "#2a2a2a" }}
								width="196"
								height="22"
								id="rect2-9" />
							<g
								id="g14-8">
								<polygon
									style={{ fill: "#2a2a2a" }}
									points="149.529,332 162.75,332 191.478,310 178.257,310 "
									id="polygon2-8" />
								<polygon
									style={{ fill: "#2a2a2a" }}
									points="183.881,332 197.103,332 225.831,310 212.61,310 "
									id="polygon3-1" />
								<polygon
									style={{ fill: "#2a2a2a" }}
									points="252.586,332 265.807,332 294.536,310 281.313,310 "
									id="polygon4-3" />
								<polygon
									style={{ fill: "#2a2a2a" }}
									points="286.938,332 300.16,332 328.888,310 315.667,310 "
									id="polygon5-8" />
								<polygon
									style={{ fill: "#2a2a2a" }}
									points="339,318.544 321.29,332 339,332 "
									id="polygon6-3" />
								<polygon
									style={{ fill: "#2a2a2a" }}
									points="218.233,332 231.455,332 260.183,310 246.962,310 "
									id="polygon7-6" />
								<polygon
									style={{ fill: "#2a2a2a" }}
									points="143,310 143,319.914 157.125,310 "
									id="polygon8-5" />
								<polygon
									style={{ fill: "#fbd35b", fillOpacity: 1 }}
									points="178.257,310 157.125,310 143,319.914 143,332 149.529,332 "
									id="polygon9-1" />
								<polygon
									style={{ fill: "#fbd35b", fillOpacity: 1 }}
									points="162.75,332 183.881,332 212.61,310 191.478,310 "
									id="polygon10-4" />
								<polygon
									style={{ fill: "#fbd35b", fillOpacity: 1 }}
									points="197.103,332 218.233,332 246.962,310 225.831,310 "
									id="polygon11-6" />
								<polygon
									style={{ fill: "#fbd35b", fillOpacity: 1 }}
									points="231.455,332 252.586,332 281.313,310 260.183,310 "
									id="polygon12-4" />
								<polygon
									style={{ fill: "#fbd35b", fillOpacity: 1 }}
									points="265.807,332 286.938,332 315.667,310 294.536,310 "
									id="polygon13-5" />
								<polygon
									style={{ fill: "#fbd35b", fillOpacity: 1 }}
									points="339,318.544 339,310 328.888,310 300.16,332 321.29,332 "
									id="polygon14-1" />
							</g>
						</g>
						<g
							id="g27-5">
							<rect
								x="143.634"
								y="309.397"
								style={{ fill: "#2a2a2a" }}
								width="194.664"
								height="3.2019999"
								id="rect15-8" />
							<g
								id="g26-5">
								<polygon
									style={{ fill: "#2a2a2a" }}
									points="191.342,309.397 178.121,309.397 174.017,312.599 187.238,312.599 "
									id="polygon15-7" />
								<polygon
									style={{ fill: "#2a2a2a" }}
									points="225.694,309.397 212.473,309.397 208.369,312.599 221.59,312.599 "
									id="polygon16-0" />
								<polygon
									style={{ fill: "#2a2a2a" }}
									points="294.399,309.397 281.178,309.397 277.073,312.599 290.295,312.599 "
									id="polygon17-7" />
								<polygon
									style={{ fill: "#2a2a2a" }}
									points="328.75,309.397 315.529,309.397 311.426,312.599 324.646,312.599 "
									id="polygon18-1" />
								<polygon
									style={{ fill: "#2a2a2a" }}
									points="260.047,309.397 246.825,309.397 242.722,312.599 255.943,312.599 "
									id="polygon19-4" />
								<polygon
									style={{ fill: "#2a2a2a" }}
									points="156.99,309.397 143.634,309.397 143.634,312.599 152.923,312.599 "
									id="polygon20-7" />
								<polygon
									style={{ fill: "#fcd51c" }}
									points="178.121,309.397 156.99,309.397 152.923,312.599 174.017,312.599 "
									id="polygon21-8" />
								<polygon
									style={{ fill: "#fcd51c" }}
									points="212.473,309.397 191.342,309.397 187.238,312.599 208.369,312.599 "
									id="polygon22-0" />
								<polygon
									style={{ fill: "#fcd51c" }}
									points="246.825,309.397 225.694,309.397 221.59,312.599 242.722,312.599 "
									id="polygon23-3" />
								<polygon
									style={{ fill: "#fcd51c" }}
									points="281.178,309.397 260.047,309.397 255.943,312.599 277.073,312.599 "
									id="polygon24-9" />
								<polygon
									style={{ fill: "#fcd51c" }}
									points="315.529,309.397 294.399,309.397 290.295,312.599 311.426,312.599 "
									id="polygon25-7" />
								<polygon
									style={{ fill: "#fcd51c" }}
									points="338.298,309.397 328.75,309.397 324.646,312.599 338.298,312.599 "
									id="polygon26-5" />
							</g>
						</g>
						<g
							id="g39-1">
							<rect
								x="143.634"
								y="309.397"
								style={{ fill: "#8e7e7e" }}
								width="194.664"
								height="3.2019999"
								id="rect27-9" />
							<g
								id="g38-4">
								<polygon
									style={{ fill: "#8e7e7e" }}
									points="191.342,309.397 178.121,309.397 174.017,312.599 187.238,312.599 "
									id="polygon27-8" />
								<polygon
									style={{ fill: "#8e7e7e" }}
									points="225.694,309.397 212.473,309.397 208.369,312.599 221.59,312.599 "
									id="polygon28-0" />
								<polygon
									style={{ fill: "#8e7e7e" }}
									points="294.399,309.397 281.178,309.397 277.073,312.599 290.295,312.599 "
									id="polygon29-3" />
								<polygon
									style={{ fill: "#8e7e7e" }}
									points="328.75,309.397 315.529,309.397 311.426,312.599 324.646,312.599 "
									id="polygon30-1" />
								<polygon
									style={{ fill: "#8e7e7e" }}
									points="260.047,309.397 246.825,309.397 242.722,312.599 255.943,312.599 "
									id="polygon31-9" />
								<polygon
									style={{ fill: "#8e7e7e" }}
									points="156.99,309.397 143.634,309.397 143.634,312.599 152.923,312.599 "
									id="polygon32-9" />
								<polygon
									style={{ fill: "#ffe67b" }}
									points="178.121,309.397 156.99,309.397 152.923,312.599 174.017,312.599 "
									id="polygon33-5" />
								<polygon
									style={{ fill: "#ffe67b" }}
									points="212.473,309.397 191.342,309.397 187.238,312.599 208.369,312.599 "
									id="polygon34-9" />
								<polygon
									style={{ fill: "#ffe67b" }}
									points="246.825,309.397 225.694,309.397 221.59,312.599 242.722,312.599 "
									id="polygon35-4" />
								<polygon
									style={{ fill: "#ffe67b" }}
									points="281.178,309.397 260.047,309.397 255.943,312.599 277.073,312.599 "
									id="polygon36-0" />
								<polygon
									style={{ fill: "#ffe67b" }}
									points="315.529,309.397 294.399,309.397 290.295,312.599 311.426,312.599 "
									id="polygon37-1" />
								<polygon
									style={{ fill: "#ffe67b" }}
									points="338.298,309.397 328.75,309.397 324.646,312.599 338.298,312.599 "
									id="polygon38-9" />
							</g>
						</g>
					</g>
					<g
						id="g86-2">
						<g
							id="g78-5">
							<g
								id="g53-4">
								<rect
									x="95"
									y="220"
									style={{ fill: "#2a2a2a" }}
									width="293"
									height="34"
									id="rect40-2" />
								<g
									id="g52-9">
									<polygon
										style={{ fill: "#2a2a2a" }}
										points="104.232,254 124.059,254 167.135,220 147.31,220 "
										id="polygon40-3" />
									<polygon
										style={{ fill: "#2a2a2a" }}
										points="155.743,254 175.569,254 218.645,220 198.821,220 "
										id="polygon41-2" />
									<polygon
										style={{ fill: "#2a2a2a" }}
										points="258.764,254 278.59,254 321.667,220 301.841,220 "
										id="polygon42-8" />
									<polygon
										style={{ fill: "#2a2a2a" }}
										points="310.275,254 330.1,254 373.177,220 353.352,220 "
										id="polygon43-0" />
									<polygon
										style={{ fill: "#2a2a2a" }}
										points="388,233.813 361.785,254 388,254 "
										id="polygon44-2" />
									<polygon
										style={{ fill: "#2a2a2a" }}
										points="207.253,254 227.079,254 270.156,220 250.33,220 "
										id="polygon45-1" />
									<polygon
										style={{ fill: "#2a2a2a" }}
										points="95,220 95,235.913 115.624,220 "
										id="polygon46-4" />
									<polygon
										style={{ fill: "#fcd51c" }}
										points="147.31,220 115.624,220 95,235.913 95,254 104.232,254 "
										id="polygon47-4" />
									<polygon
										style={{ fill: "#fbd35b", fillOpacity: 1 }}
										points="124.059,254 155.743,254 198.821,220 167.135,220 "
										id="polygon48-6" />
									<polygon
										style={{ fill: "#fbd35b", fillOpacity: 1 }}
										points="175.569,254 207.253,254 250.33,220 218.645,220 "
										id="polygon49-3" />
									<polygon
										style={{ fill: "#fbd35b", fillOpacity: 1 }}
										points="227.079,254 258.764,254 301.841,220 270.156,220 "
										id="polygon50-1" />
									<polygon
										style={{ fill: "#fbd35b", fillOpacity: 1 }}
										points="278.59,254 310.275,254 353.352,220 321.667,220 "
										id="polygon51-6" />
									<polygon
										style={{ fill: "#fbd35b", fillOpacity: 1 }}
										points="388,233.813 388,220 373.177,220 330.1,254 361.785,254 "
										id="polygon52-6" />
								</g>
							</g>
							<g
								id="g65-1">
								<rect
									x="95.393997"
									y="219.802"
									style={{ fill: "#2a2a2a" }}
									width="291.89401"
									height="4.9060001"
									id="rect53-3" />
								<g
									id="g64-3">
									<polygon
										style={{ fill: "#2a2a2a" }}
										points="166.931,219.802 147.106,219.802 140.952,224.708 160.777,224.708 "
										id="polygon53-3" />
									<polygon
										style={{ fill: "#2a2a2a" }}
										points="218.441,219.802 198.616,219.802 192.462,224.708 212.288,224.708 "
										id="polygon54-0" />
									<polygon
										style={{ fill: "#2a2a2a" }}
										points="321.462,219.802 301.636,219.802 295.482,224.708 315.309,224.708 "
										id="polygon55-6" />
									<polygon
										style={{ fill: "#2a2a2a" }}
										points="372.973,219.802 353.146,219.802 346.993,224.708 366.819,224.708 "
										id="polygon56-5" />
									<polygon
										style={{ fill: "#2a2a2a" }}
										points="269.952,219.802 250.127,219.802 243.973,224.708 263.798,224.708 "
										id="polygon57-6" />
									<polygon
										style={{ fill: "#2a2a2a" }}
										points="115.42,219.802 95.394,219.802 95.394,224.708 109.322,224.708 "
										id="polygon58-6" />
									<polygon
										style={{ fill: "#fcd51c" }}
										points="147.106,219.802 115.42,219.802 109.322,224.708 140.952,224.708 "
										id="polygon59-8" />
									<polygon
										style={{ fill: "#fcd51c" }}
										points="198.616,219.802 166.931,219.802 160.777,224.708 192.462,224.708 "
										id="polygon60-3" />
									<polygon
										style={{ fill: "#fcd51c" }}
										points="250.127,219.802 218.441,219.802 212.288,224.708 243.973,224.708 "
										id="polygon61-7" />
									<polygon
										style={{ fill: "#fcd51c" }}
										points="301.636,219.802 269.952,219.802 263.798,224.708 295.482,224.708 "
										id="polygon62-0" />
									<polygon
										style={{ fill: "#fcd51c" }}
										points="353.146,219.802 321.462,219.802 315.309,224.708 346.993,224.708 "
										id="polygon63-2" />
									<polygon
										style={{ fill: "#fcd51c" }}
										points="387.288,219.802 372.973,219.802 366.819,224.708 387.288,224.708 "
										id="polygon64-4" />
								</g>
							</g>
							<g
								id="g77-7">
								<rect
									x="95.393997"
									y="219.802"
									style={{ fill: "#8e7e7e" }}
									width="291.89401"
									height="4.9060001"
									id="rect65-4" />
								<g
									id="g76-0">
									<polygon
										style={{ fill: "#8e7e7e" }}
										points="166.931,219.802 147.106,219.802 140.952,224.708 160.777,224.708 "
										id="polygon65-1" />
									<polygon
										style={{ fill: "#8e7e7e" }}
										points="218.441,219.802 198.616,219.802 192.462,224.708 212.288,224.708 "
										id="polygon66-6" />
									<polygon
										style={{ fill: "#8e7e7e" }}
										points="321.462,219.802 301.636,219.802 295.482,224.708 315.309,224.708 "
										id="polygon67-1" />
									<polygon
										style={{ fill: "#8e7e7e" }}
										points="372.973,219.802 353.146,219.802 346.993,224.708 366.819,224.708 "
										id="polygon68-5" />
									<polygon
										style={{ fill: "#8e7e7e" }}
										points="269.952,219.802 250.127,219.802 243.973,224.708 263.798,224.708 "
										id="polygon69-0" />
									<polygon
										style={{ fill: "#8e7e7e" }}
										points="115.42,219.802 95.394,219.802 95.394,224.708 109.322,224.708 "
										id="polygon70-6" />
									<polygon
										style={{ fill: "#ffe67b" }}
										points="147.106,219.802 115.42,219.802 109.322,224.708 140.952,224.708 "
										id="polygon71-8" />
									<polygon
										style={{ fill: "#ffe67b" }}
										points="198.616,219.802 166.931,219.802 160.777,224.708 192.462,224.708 "
										id="polygon72-5" />
									<polygon
										style={{ fill: "#ffe67b" }}
										points="250.127,219.802 218.441,219.802 212.288,224.708 243.973,224.708 "
										id="polygon73-2" />
									<polygon
										style={{ fill: "#ffe67b" }}
										points="301.636,219.802 269.952,219.802 263.798,224.708 295.482,224.708 "
										id="polygon74-0" />
									<polygon
										style={{ fill: "#ffe67b" }}
										points="353.146,219.802 321.462,219.802 315.309,224.708 346.993,224.708 "
										id="polygon75-4" />
									<polygon
										style={{ fill: "#ffe67b" }}
										points="387.288,219.802 372.973,219.802 366.819,224.708 387.288,224.708 "
										id="polygon76-2" />
								</g>
							</g>
						</g>
						<g
							id="g85-9">
							<g
								id="g81-2">
								<g
									id="g79-2">
									<rect
										x="125"
										y="254"
										style={{ fill: "#bfbbb6" }}
										width="22"
										height="131"
										id="rect78-2" />
									<rect
										x="122"
										y="383"
										style={{ fill: "#d6d3d0" }}
										width="28"
										height="5"
										id="rect79-7" />
								</g>
								<g
									id="g80-6">
									<rect
										x="126.056"
										y="254.14301"
										style={{ fill: "#a5a19d" }}
										width="21.257999"
										height="12.265"
										id="rect80-6" />
								</g>
							</g>
							<g
								id="g84-1">
								<g
									id="g82-3">
									<rect
										x="336"
										y="254"
										style={{ fill: "#bfbbb6" }}
										width="21"
										height="131"
										id="rect81-8" />
									<rect
										x="332"
										y="383"
										style={{ fill: "#d6d3d0" }}
										width="29"
										height="5"
										id="rect82-4" />
								</g>
								<g
									id="g83-1">
									<rect
										x="336.185"
										y="254.14301"
										style={{ fill: "#a5a19d" }}
										width="21.259001"
										height="12.265"
										id="rect83-8" />
								</g>
							</g>
						</g>
					</g>
				</g>
				<g
					id="g89-8">
					<polygon
						style={{ fill: "#bd7000" }}
						points="329.887,296.434 309.94,376.889 356.727,376.889 336.782,296.434 "
						id="polygon87-5" />
					<rect
						x="298.84698"
						y="376.616"
						style={{ fill: "#bd7009" }}
						width="68.974998"
						height="11.208"
						id="rect87-2" />
					<polygon
						style={{ fill: "#bfcbd1" }}
						points="349.179,346.44 317.487,346.44 313.856,361.096 352.811,361.096 "
						id="polygon88-4" />
					<polygon
						style={{ fill: "#bfcbd1" }}
						points="339.773,308.504 326.893,308.504 323.9,320.575 342.767,320.575 "
						id="polygon89-8" />
				</g>
				<g
					id="g92-2">
					<polygon
						style={{ fill: "#f6a908", fillOpacity: 1 }}
						points="370.766,297.092 350.82,377.547 397.607,377.547 377.661,297.092 "
						id="polygon90-2" />
					<rect
						x="339.72699"
						y="377.27399"
						style={{ fill: "#ffbb61" }}
						width="68.974998"
						height="11.208"
						id="rect90-5" />
					<polygon
						style={{ fill: "#d9e3e9" }}
						points="390.059,347.098 358.367,347.098 354.736,361.754 393.691,361.754 "
						id="polygon91-0" />
					<polygon
						style={{ fill: "#d9e3e9" }}
						points="380.653,309.162 367.773,309.162 364.78,321.233 383.647,321.233 "
						id="polygon92-2" />
				</g>
				<g
					id="g95-9">
					<polygon
						style={{ fill: "#f6a908", fillOpacity: 1 }}
						points="213.562,296.025 193.616,376.48 240.403,376.48 220.457,296.025 "
						id="polygon93-8" />
					<rect
						x="182.52299"
						y="376.20901"
						style={{ fill: "#ffbb61" }}
						width="68.973999"
						height="11.207"
						id="rect93-4" />
					<polygon
						style={{ fill: "#d9e3e9" }}
						points="232.854,346.031 201.163,346.031 197.532,360.688 236.488,360.688 "
						id="polygon94-9" />
					<polygon
						style={{ fill: "#d9e3e9" }}
						points="223.449,308.096 210.569,308.096 207.577,320.167 226.443,320.167 "
						id="polygon95-5" />
				</g>
				<g
					id="g96-6">
					<path
						style={{ fill: "#f5c455", fillOpacity: 1 }}
						d="m 81.932,244.876 72.97,-60.362 c 0,0 -26.598,-19.492 -56.525,6.633 -29.321,25.594 -16.445,53.729 -16.445,53.729 z"
						id="path95-9" />
					<path
						style={{ fill: "#f6a908", fillOpacity: 1 }}
						d="m 97.245,266.73 78.967,-65.324 c 0,0 -36.911,-35.042 -72.765,-6.646 -35.853,28.397 -9.755,72.351 -6.202,71.97 z"
						id="path96-8" />
					<polygon
						style={{ fill: "#f5c455", fillOpacity: 1 }}
						points="75.781,288.32 71.385,282.19 94.376,263.172 176.144,195.532 180.539,201.664 "
						id="polygon96-9" />

					<rect
						x="111.081"
						y="185.291"
						transform="matrix(0.7705,-0.6375,0.6375,0.7705,-107.9108,123.1085)"
						style={{ fill: "#f5c455", fillOpacity: 1 }}
						width="11.822"
						height="52.215"
						id="rect96-2" />
				</g>
				<g
					id="g101-6">
					<path
						style={{ fill: "#bcc8ce" }}
						d="m 275.742,376.451 c 0.51,3.403 1.215,8.154 -2.547,9.969 -4.581,2.21 -7.506,-2.787 -8.113,-6.651 l -25.84,-174.2 c -1.499,-9.527 -0.327,-17.627 2.616,-18.095 2.946,-0.455 6.547,6.887 8.045,16.418 -0.001,0 25.589,170.889 25.839,172.559 z"
						id="path97-9" />
					<path
						style={{ fill: "#909fa3" }}
						d="m 249.549,201.899 c -1.655,-8.538 -4.955,-14.847 -7.691,-14.425 -2.779,0.441 -3.975,7.69 -2.838,16.518 3.502,-0.728 7.013,-1.425 10.529,-2.093 z"
						id="path98-4" />
					<g
						id="g100-9">
						<g
							id="g99-2">
							<polygon
								style={{ fill: "#cd4545;fill-opacity:1" }}
								points="256.949,94.765 290.916,136.811 271.488,187.25 218.091,195.645 184.123,153.599 203.552,103.158 "
								id="polygon98-8" />
							<path
								style={{ fill: "#dcdcdc" }}
								d="m 273.114,189.262 -55.953,8.794 -35.593,-44.056 20.359,-52.852 55.951,-8.796 35.593,44.057 z m -54.095,3.971 50.844,-7.993 18.499,-48.029 -32.341,-40.033 -50.845,7.992 -18.5,48.028 z"
								id="path99-1" />
						</g>


					</g>
				</g>
			</g>

		</svg>

	)
}

export default ConstructionIcon;