import React from "react";
import { Button, CheckboxGroup, Link, tv } from "@nextui-org/react";
import { useFormDataContext} from "../FormDataContext"
import { serviceOptions } from "../data"
import MultiSelect from "./inputs/MultiSelect";
import { RightArrowIcon } from "./icons";

const checkboxgroupStyles = tv({
	slots: {
		wrapper: "grid xs:grid-cols-2 gap-1 w-full",
	}
})

function MiniContactForm() {
	const { services, setServices } = useFormDataContext();

	let styles: React.CSSProperties = {
		width: "425px",
		background: "white",
		borderRadius: "25px",
		boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
	}

	const cbStyles = checkboxgroupStyles()

	return (
		<div style={styles} className=" px-4 sm:px-8 py-5">
			<h2>Start Your Free Estimate!</h2>

			<h3 className="mt-4 mb-4">What services are you looking for?</h3>
			<CheckboxGroup
				value={services}
				onValueChange={setServices}
				classNames={{
					wrapper: cbStyles.wrapper(),
				}}
			>
				{serviceOptions.map(service => <MultiSelect key={service.value} value={service.value}>{service.label}</MultiSelect>)}
			</CheckboxGroup>
			
			<div className="flex justify-end">
				<Button href="/contact" as={Link} className="mt-6" color="primary" endContent={<RightArrowIcon />}>Get Started</Button>
			</div>


		</div>
	)
}

export default MiniContactForm;