import React from "react";

interface IconProps {
	color?: string,
	size?: string,
	height?: string,
	width?: string,
}

function RightArrowIcon(props: IconProps) {
	const { color, size, height, width } = props

	return (
		<svg width={size || width || 24} height={size || height || 24} viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M19.8413 8.15375L28.1876 16.5L19.8413 24.8462" stroke={color || 'currentColor'} strokeWidth="4" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M4.8125 16.5H27.9537" stroke={color || 'currentColor'} strokeWidth="4" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
		</svg>

	)
}

export default RightArrowIcon;